import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Footer from "./Footer";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

function ExchangeSecond() {
    const [profitRate, setProfitRate] = useState('');
    const [wallet, setWallet] = useState('');
    const [userId, setUserId] = useState(localStorage.getItem("id") || 0);
    const [user, setUser] = useState({
        receive_wallet: "",
        phone: "",
        email: "",
    });
    const [methods, setMethods] = useState([]);
    const [payExchange, setPayExchange] = useState([]);
    const [loading, setLoading] = useState(true);

    const Photo = useRef(null);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const location = useLocation();
    const { firstMethod, secondMethod, amount, receivedAmount } = location.state || {};

    useEffect(() => {
        if (!userId) {
            window.location.href = '/';
        } else {
            loadMethods();
            loadPayExchanges();
        }
    }, [userId]);

    const loadMethods = async () => {
        try {
            const result = await axios.get("/pay_methods", {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
                }
            });
            setMethods(result.data);
        } catch (error) {
            console.error("Error loading Methods:", error);
        }
    };

    const loadPayExchanges = async () => {
        try {
            const result = await axios.get("/pay_exchange", {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
                }
            });
            setPayExchange(result.data);
        } catch (error) {
            console.error("Error loading PayExchanges:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (firstMethod && secondMethod) {
            calculateProfitRate(firstMethod, secondMethod);
        }
    }, [firstMethod, secondMethod, amount, payExchange]);

    const calculateProfitRate = (firstMethod, secondMethod) => {
        const exchange = payExchange.find(item => item.first_method === firstMethod && item.second_method === secondMethod);
        if (exchange) {
            setWallet(exchange.wallet);
        } else {
            setWallet('');
        }
    };

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleFinalSubmit = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        const transactionId = Math.floor(Math.random() * 1000000);
        formData.append("first_method_id", firstMethod);
        formData.append("second_method_id", secondMethod);
        formData.append("amount_send", amount);
        formData.append("amount_receive", receivedAmount);
        formData.append("phone", user.phone);
        formData.append("email", user.email);
        formData.append("transaction_id", transactionId);
        formData.append("user_id", userId);
        formData.append("receive_wallet", user.receive_wallet);
        formData.append('image', Photo.current.files[0]);
        formData.append("status", 0);

        try {
            const response = await axios.post(
                "/exchanges", {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "multipart/form-data" ,
                },
                formData,
            });

            if (response.data) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sent Successfully',
                });

                const firstMethodName = methods.find(method => method.id === firstMethod)?.name_en;
                const secondMethodName = methods.find(method => method.id === secondMethod)?.name_en;

                navigate('/Thanks', {
                    state: {
                        transactionId,
                        firstMethodName,
                        secondMethodName,
                        amount,
                        receivedAmount: profitRate,
                    }
                });

                sendEmail({
                    transactionId,
                    firstMethodName,
                    secondMethodName,
                    amount,
                    receivedAmount: profitRate,
                    receiveWallet: user.receive_wallet,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Submission failed',
                });
            }
        } catch (error) {
            console.error("Error during registration:", error);
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Submission failed',
            });
        }
    };

    const sendEmail = (data) => {
        const emailForm = document.createElement('form');

        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = data[key];
                emailForm.appendChild(hiddenField);
            }
        }
        emailjs.sendForm(
            'service_fra71n6',
            'template_n43a9jb',
            emailForm,
            'WDG9NePNUltT6gljD'
        ).then(
            (result) => {
                // console.log(result.text);
            },
            (error) => {
                // console.log(error.text);
            }
        );
    };

    return (
        <>
            <section className="main-banner" id="top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 col-md-9">
                            <div className="singles-contacts-box">
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <form onSubmit={handleFinalSubmit} id="second-form">
                                        <div className="row">
                                            {wallet && (
                                                <div className="col-lg-12">
                                                    <p>{t("Wallet")}: {wallet}</p>
                                                </div>
                                            )}
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input
                                                        placeholder={t("ReceiveWallet")}
                                                        type="text"
                                                        name="receive_wallet"
                                                        value={user.receive_wallet}
                                                        onChange={onInputChange}
                                                        className='form-control'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input
                                                        placeholder={t("YourPhone")}
                                                        type="text"
                                                        name="phone"
                                                        value={user.phone}
                                                        onChange={onInputChange}
                                                        className='form-control'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input
                                                        placeholder={t("Yourmail")}
                                                        type="text"
                                                        name="email"
                                                        value={user.email}
                                                        onChange={onInputChange}
                                                        className='form-control'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <p>{t("Proofpayment")}</p>
                                                    <input
                                                        type="file"
                                                        name="Photo"
                                                        ref={Photo}
                                                        className='form-control'
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-button col-md-6 m-auto">
                                                <button className="signinbtn" type="submit">{t("Exchange")}</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ExchangeSecond;
