import React, { useState, useEffect } from "react";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from "./firebase";
import Swal from 'sweetalert2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [oobCode, setOobCode] = useState('');
  const [email, setEmail] = useState(''); // Added email state
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('oobCode');
    if (code) {
      setOobCode(code);
      verifyPasswordResetCode(auth, code)
        .then((email) => {
          setIsValid(true);
          setEmail(email); // Set email when verifying the code
        })
        .catch((error) => {
          console.error("Error verifying code:", error);
          Swal.fire({
            icon: 'error',
            title: 'Invalid or expired action code',
            text: 'The action code is either invalid or expired. Please try again.'
          });
        });
    }
  }, [searchParams]);

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please make sure both passwords are the same.'
      });
      return;
    }
    if (!isValid) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid request',
        text: 'The reset code is not valid.'
      });
      return;
    }

    try {
      // Confirm password reset with Firebase
      await confirmPasswordReset(auth, oobCode, password);

      // Send password update request to PHP backend
      const formData = new FormData();
      formData.append('email', email);
      formData.append('new_password', password);
      // console.log(email,password);
      await axios.post('/users', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
        }
      });

      Swal.fire({
        icon: 'success',
        title: 'Password reset successful',
        text: 'Your password has been reset. You can now log in with the new password.'
      }).then(() => {
        navigate('/Signin'); // Redirect to login page after successful reset
      });
    } catch (error) {
      console.error("Error resetting password:", error);
      Swal.fire({
        icon: 'error',
        title: 'Password reset failed',
        text: 'An error occurred while resetting your password. Please try again later.'
      });
    }
  };

  return (
    <div>
      <section className="main-banner" id="top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-9">
              <div className="singles-contacts-box">
                <div id="second-form" className="invitioncard py-5 text-center">

                  <h2>Reset Password</h2>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label htmlFor="password">New Password:</label>
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="confirm_password">Confirm Password:</label>
                      <input
                        type="password"
                        id="confirm_password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                      />
                    </div>
                    <button type="submit">Reset Password</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}

export default ResetPassword;
