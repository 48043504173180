import React, { Component, useEffect, useState } from "react";
import Footer from "./Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import parse from 'react-html-parser';

function Bonusdetail() {
  const { id } = useParams();
  const [bonuss, setBonus] = useState([]);
  const { t } = useTranslation();
  // const { description, image } = bonus_req;
  const [user, setUser] = useState("");
  useEffect(() => {
    var name = localStorage.getItem("name");
    setUser(name);
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };
  // const [bonus_req, setBonusReq] = useState({
  //   description: "",
  //   image: "",
  // });

  // const onInputChange = (e) => {
  //   setBonusReq({ ...bonus_req, [e.target.name]: e.target.value });
  // };

  // const submit = async (e) => {
  //   e.preventDefault()
  //   try {
  //     let formData = new FormData();

  //     formData.append("user_id", name);
  //     formData.append("email", email);
  //     formData.append("message", message);
  //     formData.append('image', Photo.current.files[0]);

  //     const response = await axios({
  //       method: "post",
  //       url: "/bonus_req" , {
  // headers: {
  //     Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    // }},
  //       data: formData,
  //       config: { headers: { "Content-Type": "multipart/form-data" } },
  //     });

  //     if (response.data) { // assuming the response has a data property
  //       alert("Thanks for Your Review");
  //     } else {
  //       alert("Failed");
  //     }
  //   } catch (error) {
  //     console.error("Error during Review:", error);
  //       alert("Failed");
  //   }

  // };
  useEffect(() => {
    loadBonus();
  }, []);

  const loadBonus = async () => {
    const result = await axios.get(
      "/bonus", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
      }
    }
    );
    setBonus(result.data.reverse());
  };
  return (
    <div>
      <Container className="SHOPALLL">
        <Row>

          {bonuss.map((bonus) => (
            <>
              {bonus.id == id ? (
                <Col lg="9" md="9" sm="9" className="bllogsdetail">
                  <img
                    src={`/exchange/uploads/bonuss/${bonus.image}`}
                    alt=""
                  />
                  <h3>{t("dir") == "ltr" ? bonus.title : bonus.title_ar}</h3>
                  <p>
                    {t("dir") == "ltr" ? parse(bonus.description) : parse(bonus.description_ar)}
                  </p>





                  <div className="finishedTask">
                    <p>Finished Task?</p>
                    {user ?
                      <Link to={`/BonusRequest/${id}`}>
                        {t("click here")}
                      </Link>
                      : <Link to="/Signin">
                        {t("click here")}
                      </Link>}
                    {/* <form onSubmit={submit} id="second-form">
                                        <div className="row">
                                      
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input placeholder={t("ReceiveWallet")} type="text" name="receive_wallet" value={user.receive_wallet} onChange={onInputChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input placeholder={t("YourPhone")} type="text" name="phone" value={user.phone} onChange={onInputChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input placeholder={t("Yourmail")} type="text" name="email" value={user.email} onChange={onInputChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <p>{t("Proofpayment")}</p>
                                                    <input type="file" name="Photo" ref={Photo} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="form-button col-md-6 m-auto">
                                                <button className="signinbtn" type="submit">{t("Exchange")}</button>
                                            </div>
                                        </div>
                                    </form> */}
                  </div>
                </Col>
              ) : (
                ""
              )}
            </>
          ))}

          <Col
            lg="3"
            md="3"
            sm="3"
            className="blogfilter wow slideInRight"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >


            <div className="thirddivblog">
              <h3 className="thirddivh3">{t("RECENT")}</h3>
              {bonuss.slice(0, 3).map((blog) => (
                <Link to={`/Bonus/${blog.id}`}>
                  <div

                    className="lllink"
                  >
                    <div className="divforthird">
                      <div>
                        <img
                          src={`/exchange/uploads/bonuss/${blog.image}`}
                          alt=""
                        />
                      </div>
                      <div>
                        <h3>{t("dir") == "ltr" ? blog.title : blog.title_ar}</h3>

                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

          </Col>

        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Bonusdetail;