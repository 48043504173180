import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import parse from 'react-html-parser';

function Exchange() {
    const [firstMethod, setFirstMethod] = useState('');
    const [secondMethod, setSecondMethod] = useState('');
    const [amount, setAmount] = useState('');
    const [profitRate, setProfitRate] = useState('');
    const [receivedAmount, setReceivedAmount] = useState('');
    const [minLimit, setMinLimit] = useState('');
    const [maxLimit, setMaxLimit] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [exchanges, setExchanges] = useState([]);
    const [methods, setMethods] = useState([]);
    const [uniqueFirstMethods, setUniqueFirstMethods] = useState([]);
    const [filteredSecondMethods, setFilteredSecondMethods] = useState([]);
    const [showSecondForm, setShowSecondForm] = useState(false);
    const [wallet, setWallet] = useState('');
    const [userId, setUserId] = useState(localStorage.getItem("id") || 0);
    const [user, setUser] = useState({
        first_method_id: "",
        second_method_id: "",
        amount_send: "",
        amount_receive: "",
        user_id: "",
        transaction_id: "",
        order_id: "",
        receive_wallet: "",
        phone: "",
        email: "",
    });

    const navigate = useNavigate();
    const { t } = useTranslation();
    const Photo = useRef(null);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language, (err, t) => {
            if (err) return console.log("Error loading language:", err);
            // console.log("Language changed to:", language);
        });
    };

    useEffect(() => {

        if (!userId) {
            window.location.href = '/';
        }
        loadExchange();
        loadMethods();
    }, []);

    const loadExchange = async () => {
        try {
            const result = await axios.get("/pay_exchange", {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, 
                  "Content-Type": "application/json"
                }
              });
            setExchanges(result.data);
            const uniqueFirsts = Array.from(new Set(result.data.map(item => item.first_method)));
            setUniqueFirstMethods(uniqueFirsts);
        } catch (error) {
            console.error("Error loading exchanges:", error);
        }
    };

    const loadMethods = async () => {
        try {
            const result = await axios.get("/pay_methods", {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
                   "Content-Type": "application/json"
                }
              });
            setMethods(result.data);
        } catch (error) {
            console.error("Error loading Methods:", error);
        }
    };

    const handleFirstMethodChange = (e) => {
        const selectedFirstMethod = e.target.value;
        setFirstMethod(selectedFirstMethod);
        const filteredSecondMethods = exchanges.filter(item => item.first_method === selectedFirstMethod);
        setFilteredSecondMethods(filteredSecondMethods.map(item => item.second_method));
        updateLimits(selectedFirstMethod, secondMethod);
        if (selectedFirstMethod && secondMethod && amount) {
            calculateProfitRate(selectedFirstMethod, secondMethod, amount);
        }
    };

    const handleSecondMethodChange = (e) => {
        const selectedSecondMethod = e.target.value;
        setSecondMethod(selectedSecondMethod);
        updateLimits(firstMethod, selectedSecondMethod);
        if (firstMethod && selectedSecondMethod && amount) {
            calculateProfitRate(firstMethod, selectedSecondMethod, amount);
        }
    };

    const handleAmountChange = (e) => {
        const selectedAmount = e.target.value;
        setAmount(selectedAmount);
        if (selectedAmount == 0 || !selectedAmount) {
            setProfitRate(0);
            setReceivedAmount(0);
          } else if (firstMethod && secondMethod && selectedAmount) {
            calculateProfitRate(firstMethod, secondMethod, selectedAmount);
          }
      
      
    };

    const updateLimits = (firstMethod, secondMethod) => {
        if (firstMethod && secondMethod) {
            const matchingExchanges = exchanges.filter(item => item.first_method === firstMethod && item.second_method === secondMethod);
            if (matchingExchanges.length > 0) {
                const { min_limit, max_limit } = matchingExchanges[0];
                setMinLimit(min_limit);
                setMaxLimit(max_limit);
            } else {
                setMinLimit('');
                setMaxLimit('');
            }
        }
    };

    const calculateProfitRate = (firstMethod, secondMethod, amount) => {
        const exchange = exchanges.find(item => item.first_method === firstMethod && item.second_method === secondMethod);
        if (exchange) {
            let rate;
            let received;
            rate = Number(amount) * Number(exchange.profit);
            received = rate;
            setProfitRate(rate);
            setWallet(exchange.wallet);
            setReceivedAmount(received);
        }
    };

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        let error = '';
        if (!amount) {
            error = `Must Enter Amount`;
        } else if (receivedAmount < minLimit) {
            error = `Received amount must be greater than or equal to ${minLimit}`;
        } else if (receivedAmount > maxLimit) {
            error = `Received amount must be less than or equal to ${maxLimit}`;
        } else if (!firstMethod || !secondMethod) {
            error = `Must Select A method`;
        }
    
        setErrorMessage(error);
        if (!error) {
            setShowSecondForm(true);
        }
    };

    const handleFinalSubmit = async (e) => {
        e.preventDefault();
        let formData = new FormData();

        const transactionId = Math.floor(Math.random() * 1000000);
        formData.append("first_method_id", firstMethod);
        formData.append("second_method_id", secondMethod);
        formData.append("amount_send", amount);
        formData.append("amount_receive", profitRate);
        formData.append("phone", user.phone);
        formData.append("email", user.email);
        formData.append("transaction_id", transactionId);
        formData.append("user_id", userId);
        formData.append("receive_wallet", user.receive_wallet);
        formData.append('image', Photo.current.files[0]);
        formData.append("status", 0);

        try {
            const response = await axios.post(
                "/exchanges",
                formData,
                { headers: {  Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,"Content-Type": "multipart/form-data" } }
            );            

            if (response.data && !response.data.error) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sent Successfully',
                });

                const firstMethodName = methods.find(method => method.id === firstMethod).name_en;
                const secondMethodName = methods.find(method => method.id === secondMethod).name_en;
                if(response.data.amount_recieve ){
                navigate('/Thanks', {
                    state: {
                        transactionId,
                        firstMethodName,
                        secondMethodName,
                        amount,
                        receivedAmount: profitRate,
                    }

                })
                sendEmail({
                    transactionId,
                    firstMethodName,
                    secondMethodName,
                    amount,
                    receivedAmount: profitRate,
                    receiveWallet: user.receive_wallet,
                });
                }
       
            } else {
                console.log(response);
                
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: response?.data?.error?response.data.error[0]:'Submission failed',
                });
            }
        } catch (error) {
            console.error("Error during registration:", error);
        
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: errorMessage?errorMessage:'Submission failed',
            });
        }
    };

    useEffect(() => {
        const name = localStorage.getItem("name");
        const userId = localStorage.getItem("id");
        if (!userId) {
            window.location.href = '/';
        }
        setUserId(userId);
        if (!name) {
            window.location.href = '/Login';
        }
    }, []);
    const sendEmail = (data) => {
        const emailForm = document.createElement('form');

        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = data[key];
                emailForm.appendChild(hiddenField);
            }
        } emailjs.sendForm(
            'service_fra71n6',
            'template_n43a9jb',
            emailForm,
            'WDG9NePNUltT6gljD'
        )
    };
    const handleProfitRateChange = (e) => {
        const newProfitRate = e.target.value;
        setProfitRate(newProfitRate);
        if (newProfitRate == 0 || !newProfitRate) {
            setReceivedAmount(0);
            setAmount(0)
        } else if (firstMethod && secondMethod && newProfitRate) {
            const exchange = exchanges.find(item => item.first_method === firstMethod && item.second_method === secondMethod);
            if (exchange) {
                const newAmount = Number(newProfitRate) / Number(exchange.profit);
                setAmount(newAmount.toFixed(2)); // Set amount with two decimals for precision
            }
        }
    };



    return (
        <>
            <section className="main-banner contactt" id="top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="singles-contacts-box">
                                <h2 className="exchangeLogo"><span>Changer</span> Money</h2>
                                {!showSecondForm ? (
                                    <form onSubmit={onSubmit} id="contact-form">
                                        <div className="row rowsmall">
                                            <div className="col-lg-12">
                                                <div className="section-heading">
                                                    <h2><em>{t("Check")}</em></h2>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <fieldset>
                                                    <input type="number" step="0.01" placeholder='0' name="firstMethod" value={amount} onChange={handleAmountChange} className='form-control' />
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-6">
                                                <fieldset>
                                                    <select value={firstMethod} onChange={handleFirstMethodChange} className='form-control'>
                                                        <option value="">{t("Send")}</option>
                                                        {uniqueFirstMethods.map(method => (
                                                            <option key={method} value={method}>
                                                                {methods.map(methodd => (
                                                                    methodd.id === method ? `${parse(methodd.name_en)}` : null
                                                                ))}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-6">

                                                <fieldset>
                                                    <input
                                                        placeholder={t("Get")}
                                                        type="number"
                                                        min={0}
                                                        step="0.01"
                                                        value={profitRate}
                                                        name="profitRate"
                                                        onChange={handleProfitRateChange}
                                                        className='form-control'
                                                    />
                                                </fieldset>

                                                {/* <input placeholder={t("Get")} type="text" value={profitRate} name="secondMethod" disabled className='form-control' /> */}
                                            </div>
                                            <div className="col-lg-6">
                                                <fieldset>
                                                    <select value={secondMethod} onChange={handleSecondMethodChange} className='form-control'>
                                                        <option value="">{t("Receive")}</option>
                                                        {filteredSecondMethods.map(method => (
                                                            <option key={method} value={method}>
                                                                {methods.map(methodd => (
                                                                    methodd.id === method ? `${parse(methodd.name_en)}` : null
                                                                ))}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-12">
                                                <fieldset>
                                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                                </fieldset>
                                            </div>
                                            <div className="form-button col-md-6 m-auto">
                                                <button className="signinbtn" type="submit">{t("Next")}</button>
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <form onSubmit={handleFinalSubmit} id="second-form">
                                        <div className="row">
                                            {wallet && (
                                                <div className="col-lg-12">
                                                    <p>{t("Wallet")}: {wallet}</p>
                                                </div>
                                            )}
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input placeholder={t("ReceiveWallet")} type="text" name="receive_wallet" value={user.receive_wallet} onChange={onInputChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input placeholder={t("YourPhone")} type="text" name="phone" value={user.phone} onChange={onInputChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <input placeholder={t("WhatsAppOr")} type="text" name="email" value={user.email} onChange={onInputChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form_box">
                                                    <p>{t("Proofpayment")}</p>
                                                    <input type="file" name="Photo" ref={Photo} className='form-control' />
                                                </div>
                                            </div>
                                      
                                            <div className="form-button col-md-6 m-auto">
                                                <button className="signinbtn" type="submit">{t("Exchange")}</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Exchange;
