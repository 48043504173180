import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { TbWorld } from "react-icons/tb";
import { CiUser } from "react-icons/ci";
import { IoHomeOutline } from "react-icons/io5";
import { GrContactInfo } from "react-icons/gr";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { GrArticle } from "react-icons/gr";
import { GiTrophyCup } from "react-icons/gi";
import { FaExchangeAlt } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { RxMoon } from "react-icons/rx";
import { CiSun } from "react-icons/ci";
import { NavItem } from "react-bootstrap";

function NavBar({ isDarkMode, toggleTheme }) {
  const [stickyClass, setStickyClass] = useState("relative");
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [user, setUser] = useState("");

  const LogOut = () => {
    localStorage.removeItem("id");

    localStorage.removeItem("name");
    localStorage.clear();
    var name = localStorage.getItem("name");
    setUser(name);
    window.location.href = '/';

  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos && currentScrollPos > 500);
    setPrevScrollPos(currentScrollPos);
    if (visible) {
      setStickyClass("fixed top-0 left-0 z-50");
    } else {
      setStickyClass("relative");
    }
  };

  useEffect(() => {
    var name = localStorage.getItem("name");
    setUser(name);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };

  const closeNavbar = () => {
    const navBar = document.querySelector(".navbar-collapse");
    if (navBar.classList.contains("show")) {
      navBar.classList.remove("show");
    }
  };

  return (
    <>
      <Navbar
        className={`h-16 header-area header-sticky navvvv w-full bg-gray-200 ${stickyClass}`}
        bg="light"
        variant="light"
        expand="lg"
      >
        {/* <div className="topNav">
          <p>{t("topNav")} <a href="https://wa.me/+201030422086">(01030422086)</a> </p>
        </div> */}
        <Container fluid className="d-flex justify-content-between nav">
          <Link to="/" className="ImgNav">
            <img src="images/logonav.PNG" alt="" />
          </Link>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="custom-navbar-collapse">
            <Nav className="ml-auto navyy">
              <NavItem className="custom-nav-item">
                <NavLink
                  className="navlinkk custom-nav-link"
                  to="/"
                  onClick={closeNavbar}
                >
                  <IoHomeOutline />
                  {t("Home")}
                </NavLink>
              </NavItem>
              {user ? (
                <>
                  <NavItem className="custom-nav-item">
                    <NavLink
                      className="navlinkk custom-nav-link"
                      to="/Exchange"
                      onClick={closeNavbar}
                    >
                      <FaExchangeAlt />
                      {t("Exchange")}
                    </NavLink>
                  </NavItem>

                </>
              ) : null}
              <NavItem className="custom-nav-item">
                <NavLink
                  className="navlinkk custom-nav-link"
                  to="/Services"
                  onClick={closeNavbar}
                >
                  <MdOutlineMiscellaneousServices />
                  {t("Services")}
                </NavLink>
              </NavItem>
              <NavItem className="custom-nav-item">
                <NavLink
                  className="navlinkk custom-nav-link"
                  to="/Blogs"
                  onClick={closeNavbar}
                >
                  <GrArticle />
                  {t("Blogs")}
                </NavLink>
              </NavItem>
              <NavItem className="custom-nav-item">
                <NavLink
                  className="navlinkk custom-nav-link"
                  to="/Bonus"
                  onClick={closeNavbar}
                >
                  <GiTrophyCup />
                  {t("Bonus")}
                </NavLink>
              </NavItem>
            
              <NavItem className="custom-nav-item">
                <NavLink
                  className="navlinkk custom-nav-link"
                  to="/ContactUs"
                  onClick={closeNavbar}
                >
                  <GrContactInfo />
                  {t("contact")}
                </NavLink>
              </NavItem>
              <NavItem className="custom-nav-item">
                <NavLink
                  className="navlinkk custom-nav-link"
                  to="/Faqs"
                  onClick={closeNavbar}
                >
                  <MdOutlineQuestionAnswer />
                  {t("FAQ")}
                </NavLink>
              </NavItem>
              <NavItem className="custom-nav-item">
                <NavLink
                  className="navlinkk custom-nav-link"
                  to="/AboutUs"
                  onClick={closeNavbar}
                >
                  <FaPeopleGroup />
                  {t("About")}
                </NavLink>
              </NavItem>
       
 
              <NavItem className="custom-nav-item">
                {user ? (
                  <NavDropdown title={user} id="basic-nav-dropdown">
                  <NavDropdown.Item>
                      <NavLink to="/TheProfile" onClick={closeNavbar}>
                        {t("UpdateProfile")}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <NavLink
                          to="/Dashboard"
                          onClick={closeNavbar}
                        >
                          {t("Dashboard")}
                        </NavLink>
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item>
                        <NavLink
                          to="/PendingTransactions"
                          onClick={closeNavbar}
                        >
                          {t("PendingTransactions")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/ProcessingTransactions"
                          onClick={closeNavbar}
                        >
                          {t("ProcessingTransactions")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/CompletedTransactions"
                          onClick={closeNavbar}
                        >
                          {t("CompletedTransactions")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/CanceledTransactions"
                          onClick={closeNavbar}
                        >
                          {t("CanceledTransactions")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/RefundedTransactions"
                          onClick={closeNavbar}
                        >
                          {t("RefundedTransactions")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/Profile"
                          onClick={closeNavbar}
                        >
                          {t("AllTransactions")}
                        </NavLink>
                      </NavDropdown.Item> */}
                      <NavDropdown.Item>
                        <NavLink
                          to="/Withdraw"
                          onClick={closeNavbar}
                        >
                          {t("NewWithdraw")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/AllWithdraw"
                          onClick={closeNavbar}
                        >
                          {t("AllWithdraw")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/NewTicket"
                          onClick={closeNavbar}
                        >
                          {t("NewTicket")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/Tickets"
                          onClick={closeNavbar}
                        >
                          {t("Tickets")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/GenerateInvitationLink"
                          onClick={closeNavbar}
                        >
                          {t("InviteFriends")}
                        </NavLink>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <NavLink
                          to="/Afflication"
                          onClick={closeNavbar}
                        >
                          {t("Afflication")}
                        </NavLink>
                      </NavDropdown.Item>
                    {/* <NavDropdown.Item >
                      <NavLink to="/ChangePassword" onClick={closeNavbar}>
                        Change Password
                      </NavLink>
                    </NavDropdown.Item> */}
                    <NavDropdown.Item href="" onClick={LogOut}>
                      {t("logout")}
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavDropdown
                    title={<CiUser className="iconnm my-2" />}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="">
                      <NavLink to="/Signin" onClick={closeNavbar}>
                      {t("Login")}
                        
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="">
                      <NavLink to="/Register" onClick={closeNavbar}>
                      {t("Register")}
                        
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </NavItem>
          
              <NavItem className="custom-nav-item darkMode" >
                <input type="checkbox" id="darkmode-toggle" />
                <label htmlFor="darkmode-toggle"  onClick={toggleTheme}>{isDarkMode ? <RxMoon className="moon" />: <CiSun className="sun" /> }</label>
              </NavItem>

              <NavItem className="custom-nav-item">
                <NavDropdown
                  title={<TbWorld className="iconnm my-2" />}
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item to="#" onClick={() => changeLanguage("en")}>
                    English
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item to="#" onClick={() => changeLanguage("ar")}>
                    العربيه
                  </NavDropdown.Item>
                </NavDropdown>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
