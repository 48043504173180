import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import './i18n';
import parse from 'react-html-parser';
import  Footer  from './Footer';
import axios from 'axios';

function Privacy() {
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (lang) => {
      i18n.changeLanguage(lang);
    };
    const [socials, setSocials] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const loadSocials = async () => {
        try {
          const result = await axios.get(
            "/social",
            {
              headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
              },
          }
          );
          setSocials(result.data);
          setLoading(false);
          
        } catch (error) {
          setLoading(false);
        }
      };
  
      loadSocials();
    }, []);
  return (
    <>
    <div className="terms_content">
    {/* <h2>{t('policy_title')}</h2>
    {t('policy', { returnObjects: true }).map((section, index) => (
      <div key={index} className="section">
        <h3>{section.title}</h3>
        <p>{parse(section.text)}</p>
      </div>
    ))} */}
    {t("dir") == "ltr" ? parse(socials[9]?.link) : parse(socials[10]?.link) }
  </div>
  <Footer />
</>
  )
}

export default Privacy
