import React, { useEffect, useState } from 'react';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import parse from 'react-html-parser';
import { Link } from 'react-router-dom';
import { TfiCup } from 'react-icons/tfi';

const Levels = () => {
    const [levels, setLevels] = useState([]);
    const [userExchanges, setUserExchanges] = useState([]);
    const [levelsReq, setLevelsReq] = useState([]);
    const { t } = useTranslation();
    const [userId, setUserId] = useState(localStorage.getItem("id") || 0);
    const [userLevel, setUserLevel] = useState(0);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language, (err, t) => {
            if (err) return console.log("Error loading language:", err);
            // console.log("Language changed to:", language);
        });
    };

    useEffect(() => {
        if (!localStorage.getItem("id")) {
            window.location.href = '/';
        } else {
            loadLevels();
            loadUserExchanges();
            loadLevelsReq();
        }
    }, [userId]);

    const loadLevelsReq = async () => {
        try {
            const result = await axios.get(
                "/level_req" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    }}
            );
            setLevelsReq(result.data);
        } catch (error) {
            console.error("Error loading level_req:", error);
        }
    };

    const loadLevels = async () => {
        try {
            const result = await axios.get(
                "/levels" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    }}
            );
            setLevels(result.data);
            determineUserLevel(result.data);

        } catch (error) {
            console.error("Error loading levels:", error);
        }
    };

    const loadUserExchanges = async () => {
        try {
            const result = await axios.get(
                `/exchanges?userId=${userId}`
            );
            setUserExchanges(result.data);
        } catch (error) {
            console.error("Error loading user exchanges:", error);
        }
    };

    const calculateTotalExchanges = () => {
        return userExchanges.reduce((total, exchange) => total + parseFloat(exchange.equal_to_dollar), 0);
    };

    const hasLevelRequest = (levelId) => {
        return levelsReq.some(req => req.level_id === levelId && req.user_id === userId && req.approved == 1);
    };

    const determineUserLevel = (levels) => {
        // Fetch approved levels for the user
        axios.get(`/level_req?user_id=${userId}`, {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"            }})
            .then(response => {
                const approvedLevels = response.data;

                if (approvedLevels.length > 0) {
                    // Get the level with the least orderr value
                    const leastOrderLevel = levels.reduce((minLevel, currentLevel) => {
                        const levelInReq = approvedLevels.find(level => level.level_id === currentLevel.id);
                        if (levelInReq && (minLevel === null || currentLevel.orderr < minLevel.orderr)) {
                            return currentLevel;
                        }
                        return minLevel;
                    }, null);

                    if (leastOrderLevel) {
                        setUserLevel(leastOrderLevel.id);
                    }
                }
            })
            .catch(error => console.error("Error fetching approved levels:", error));
    };

    return (
        <section className="main-banner" id="top">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-9">
                        <div className="singles-contacts-box">
                            <div id="second-form" className="invitioncard py-5 text-center">
                                <div className="levels-container">

                                    {levels.length > 0 ?
                                        levels.map(level => {
                                            const userTotalExchanges = calculateTotalExchanges();
                                            const userExchangesCount = userExchanges.length;
                                            const showClickHere = userExchangesCount >= level.exchanges_required &&
                                                userTotalExchanges >= level.balance_req &&
                                                !hasLevelRequest(level.id);
                                            return (
                                                <div className={`level-item ${userLevel == level.id ? 'highlighted' : ''}`} key={level.id}>
                                                    <img
                                                        src={`/exchange/uploads/levels/${level.image}`}
                                                        alt=""
                                                        className="level-image"
                                                    />
                                                    <div className='d-flex justify-content-between w-100'>
                                                        <p>{t("TotalExchangesNumberReq")}</p>
                                                        <h3>{level.exchanges_required}</h3>
                                                    </div>
                                                    <div className='d-flex justify-content-between w-100'>
                                                        <p>{t("TotalExchangesBalanceReq")}   </p>
                                                        <h3>{level.balance_req}</h3>
                                                    </div>
                                                    <h3 className="level-title text-center">
                                                        {t("dir") === "ltr" ? level.title_en : level.title_ar}
                                                    </h3>
                                                    <p className="level-description">{t("dir") === "ltr" ? parse(level.description_en) : parse(level.description_ar)}</p>
                                                    {showClickHere && (
                                                        <Link to={`/LevelsUpgrade/${level.id}`} className='btn m-auto btnLevel'>
                                                            {t("click here")}
                                                        </Link>
                                                    )}
                                                </div>
                                            );
                                        }) : <div className='text-center'>

                                            <TfiCup size={100} />
                                            <h3 className='mx-auto my-5' >{i18n.language === "ar" ? 'بدون ترقيه ' : 'No Level'}</h3>
                                        </div>}




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Levels;
