import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { auth, facebookProvider, googleProvider } from './firebase'; // Ensure correct imports
import { signInWithPopup, signOut } from "@firebase/auth";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import Swal from 'sweetalert2';
import { FaEye, FaEyeSlash } from "react-icons/fa6";

function Signin() {
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // Track password visibility

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => {
    var name = localStorage.getItem("name");
    setUser(name);
    if (name) {
      window.location.href = '/';
    }
  }, []);

  const onLogin = async (e) => {
    // if (!email || !password) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Incomplete Form',
    //     text: 'Please fill in all the required fields.',
    //   });
    //   return;
    // }
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // Handle user login
      let formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      const response = await axios.post("/users", formData,     {headers: { "Content-Type": "multipart/form-data" ,
        'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` 
    }});
      if (response.data) {
        window.localStorage.setItem("name", response.data.username);
        window.localStorage.setItem("id", response.data.id);
        console.log(response.data.id);
        Swal.fire({
          icon: 'success',
          title: 'Sign In Successfully',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          window.location.href = '/';
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Email or password are wrong!'
        });
      }
    } catch (error) {
      // console.log(error.code, error.message);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Email or password are wrong!'
      });
    }
  };

  const login = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      setIsLogin(true);
      let formData = new FormData();
      if (provider === facebookProvider) {
        formData.append("email", result.user.email);
      } else {
        formData.append("email", result.user.email);
      }
      formData.append("password", result.user.password);
      const response = await axios.post("/users", formData, {
        headers: { "Content-Type": "multipart/form-data" ,
              'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` 
        }
      });
      if (response.data) {
        window.localStorage.setItem("name", response.data.username);
        window.localStorage.setItem("id", response.data.id);
        Swal.fire({
          icon: 'success',
          title: 'Sign In Successfully',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          window.location.href = '/';
        });
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e?e.response.data.error:'Email or password are wrong!'
      });
      setIsLogin(false);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsLogin(false);
    } catch (e) {
      // console.log(e);
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <section className="main-banner signinform" id="top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <img src="images/loginn.png" alt="" width="100px" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="singles-contacts-box">
                <form onSubmit={(e) => onLogin(e)} id="contact-form">
                  <FacebookLoginButton
                      className="facea"
                    onClick={() => login(facebookProvider)}
                  >
                  {t("LogInWithFacebook")}
                  </FacebookLoginButton>
                  <GoogleLoginButton
                    className="googlea"
                    onClick={() => login(googleProvider)}
                  >
                                    {t("LogInWithGoogle")}

                  </GoogleLoginButton>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <fieldset className="form_box">
                        <input
                          type="text"
                          placeholder={t("Yourmail")}
                          name="email"
                          className='form-control'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box password-container">
                        <input
                          type={showPassword ? "text" : "password"}

                          placeholder={t("Password")}
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="toggle-password" onClick={toggleShowPassword}>
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </div>
                  </div>
                  <h5>
                    {t("Forgotyourpasswordd")} <Link to="/forgot-password">{t("ClickHere")}</Link>
                  </h5>
                  <div className="form-button">
                    <button className="signinbtn">{t("SignIn")}</button>
                  </div>
                  <h5>
                    {t("Donthave")} <Link to="/Register"> {t("CreateNow")} </Link>
                  </h5>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Signin;
