import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebookF, FaFacebookMessenger, FaTelegramPlane, FaYoutube } from "react-icons/fa";
import { IoLogoWhatsapp, IoIosCall, IoLogoInstagram } from "react-icons/io";
import { RiTwitterXFill, RiContactsBookFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { IoIosArrowDown } from "react-icons/io";
import axios from "axios";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  const { t } = useTranslation();
  const [openSections, setOpenSections] = useState([]);
  const [showFixedButtons, setShowFixedButtons] = useState(false);
  const [socials, setSocials] = useState([]);
  const [loading, setLoading] = useState(true);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      console.log("Language changed to:", language);
    });
  };

  const toggleSection = (section) => {
    setOpenSections((prevOpenSections) =>
      prevOpenSections.includes(section)
        ? prevOpenSections.filter((s) => s !== section)
        : [...prevOpenSections, section]
    );
  };

  const toggleFixedButtons = () => {
    setShowFixedButtons((prevShowFixedButtons) => !prevShowFixedButtons);
  };

  useEffect(() => {
    const loadSocials = async () => {
      try {
        const result = await axios.get(
          "/social",
          {
              headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
              },
        }
        );
        setSocials(result.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching social media data:", error);
        setLoading(false);
      }
    };

    loadSocials();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <footer>
      <Container>
        <Row>
          <Col lg="2" md="2" sm="12">
            <img src="images/logo2.png" alt="" className="footerimg" />
          </Col>
          <Col lg="3" md="3" sm="12">
            <h3 onClick={() => toggleSection("company")}>
              {t("Company")} <IoIosArrowDown />
            </h3>
            <div className={`accordion-content ${openSections.includes("company") ? "open" : ""}`}>
              <Link to="/"><p>{t("Home")}</p></Link>
              <Link to="/AboutUs"><p>{t("About")}</p></Link>
              <Link to="/ContactUs"><p>{t("contact")}</p></Link>
            </div>
          </Col>
          <Col lg="2" md="2" sm="12">
            <h3 onClick={() => toggleSection("pages")}>
              {t("Pages")} <IoIosArrowDown />
            </h3>
            <div className={`accordion-content ${openSections.includes("pages") ? "open" : ""}`}>
              <Link to="/Blogs"><p>{t("Blogs")}</p></Link>
              <Link to="/Bonus"><p>{t("Bonus")}</p></Link>
              <Link to="/Services"><p>{t("Services")}</p></Link>
            </div>
          </Col>
          <Col lg="2" md="2" sm="12">
            <h3 onClick={() => toggleSection("faqs")}>
              {t("footerfaq")} <IoIosArrowDown />
            </h3>
            <div className={`accordion-content ${openSections.includes("faqs") ? "open" : ""}`}>
              <Link to="/Faqs"><p>{t("FAQ")}</p></Link>
              <Link to="/Privacy"><p>{t("Policy1")}</p></Link>
              <Link to="/TermsConditions"><p>{t("terms_title")}</p></Link>
            </div>
          </Col>
          <Col lg="3" md="3" sm="12">
            <h3>{t("Follow")}</h3>
            <div>
              <div className="divicons">
                {socials[0]?.status === '1' && <a href={socials[0]?.link} target="_blank"><div><FaFacebookF /></div></a>}
                {socials[1]?.status === '1' && <a href={socials[1]?.link} target="_blank"><div><IoLogoInstagram /></div></a>}
                {socials[4]?.status === '1' && <a href={socials[4]?.link} target="_blank"><div><FaLinkedinIn /></div></a>}
                {socials[8]?.status === '1' && <a href={socials[8]?.link} target="_blank"><div><FaYoutube /></div></a>}

              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="fixed-buttons">
        {showFixedButtons && (
          <>

            {socials[7]?.status === '1' && <a href={socials[7]?.link} target="_blank"><button className="fixed-button"><FaFacebookMessenger /></button></a>}

            {socials[3]?.status === '1' && <a href={`https://wa.me/${socials[3]?.link}`} target="_blank"><button className="fixed-button"><IoLogoWhatsapp /></button></a>}
            {socials[2]?.status === '1' && <a href={`https://t.me/${socials[2]?.link}`} target="_blank"><button className="fixed-button"><FaTelegramPlane /></button></a>}
            {socials[5]?.status === '1' && <a href={`tel:${socials[5]?.link}`} target="_blank"><button className="fixed-button"><IoIosCall /></button></a>}
          </>
        )}
        <button onClick={toggleFixedButtons} className="fixed-button"><RiContactsBookFill /></button>
      </div>

      <p className="copyright">© 2024 Changer Money All rights reserved</p>
    </footer>
  );
}

export default Footer;
