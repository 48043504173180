import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import axios from "axios";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
function Afflication() {
    const [methods, setMethod] = useState([]);
    const [userId, setUserId] = useState(0);
    const [refrences, setRefrence] = useState([]);
    const { t } = useTranslation();

    const changeLanguage = (language) => {
      i18n.changeLanguage(language, (err, t) => {
        if (err) return console.log("Error loading language:", err);
        // console.log("Language changed to:", language);
      });
    };
    useEffect(() => {
        loadMethod();
        loadRefrence();
        var userId = localStorage.getItem("id");
        if (!userId) {
            window.location.href = '/';
        }
        setUserId(userId);

    }, []);

    const loadMethod = async () => {
        const result = await axios.get(
            "/users" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    }}
        );
        setMethod(result.data);
    };

    const loadRefrence = async () => {
        const result = await axios.get(
            "/pay_methods" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    }}
        );
        setRefrence(result.data);
    };
    const userMethods = methods.filter(method => method.who_invites == userId);

    return (
        <>

            <section className="infos infodifferr" id="infos">
                <div className="container">
                    <Table>
                        <thead>
                            <tr className=" headerHome">
                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("userinvitedid")}</span></p>
                                        </div>
                                    </div>
                                </th>


                                <th>
                                    <div className="change">

                                        <div className="change-thumb">

                                            <p><span>{t("userinvitedusername")}</span></p>
                                        </div>
                                    </div>
                                </th>

                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("codeinvitedwith")}</span></p>
                                        </div>
                                    </div>
                                </th>

                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("GotBonus")}</span></p>
                                        </div>
                                    </div>
                                </th>




                            </tr>
                        </thead>
                        <tbody>
                            {userMethods.length === 0 ? (
                                <p style={{ textAlign: "center" }} className="my-5">{t("NoPreviousAfflications")}</p>
                            ) : (
                                userMethods.map((method) => (
                                    <tr>
                                        <td>
                                            <div className="amrcat-cap-content">
                                                <p><span>{method.id}</span></p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="amrcat-cap-content">
                                                <p><span>{method.username}</span></p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="amrcat-cap-content">
                                                <p><span>{method.code_invited_with}</span></p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="amrcat-cap-content">
                                                <p><span>{method.invitation_used ? 'yes' : 'no'}</span></p>
                                            </div>
                                        </td>
                                    </tr>


                                )))}
                        </tbody>
                    </Table>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Afflication;
