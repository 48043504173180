import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { auth } from './firebase'; // Ensure correct import
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from '@firebase/auth';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userId, setUserId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const storedUserId = localStorage.getItem("id");
      if (!storedUserId) {
        window.location.href = '/';
        throw new Error("User ID not found in localStorage");
      }
      setUserId(storedUserId);
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'oldPassword') {
      setOldPassword(value);
    } else if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'New password and confirm password do not match.'
      });
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No user is currently signed in.'
        });
        return;
      }

      // Reauthenticate user with current password
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);

      // Update the password in Firebase
      await updatePassword(user, newPassword);

      // Update the password in your backend
      let formData = new FormData();
      formData.append("old_password", oldPassword);

      formData.append("new_password", newPassword);

      formData.append("id", userId);
    
      // console.log(email,password);
      await axios.post(`/users`, formData, {
     headers: { "Content-Type": "multipart/form-data" ,
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` 
    },
      });
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Password changed successfully.'
      });
    } catch (error) {
      console.error("Error changing password:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while changing the password.'
      });
    }
  };

  return (
    <section className="main-banner" id="top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-9">
            <div className="singles-contacts-box">
              <div id="second-form" className="invitioncard py-5 text-center">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-heading">
                        <h2><em>Change</em> Password</h2>
                      </div>
                    </div>
                    <div className="row py-5">
                      <div className="col-lg-12 col-md-12">
                        <div className="form_box">
                          <input type="password" name="oldPassword" placeholder="Old Password" value={oldPassword} onChange={handleChange} className='form-control' required />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form_box">
                          <input type="password" name="newPassword" placeholder="New Password" value={newPassword} onChange={handleChange} className='form-control' required />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form_box">
                          <input type="password" name="confirmPassword" placeholder="Confirm New Password" value={confirmPassword} onChange={handleChange} className='form-control' required />
                        </div>
                      </div>
                      <div className="form-button col-md-6 m-auto">
                        <button className="signinbtn" type="submit">Change Password</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
