import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../Footer";
import { Button, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { FaEye } from "react-icons/fa";
import parse from 'react-html-parser';
import Swal from "sweetalert2";

function Profile() {
    const [methods, setMethods] = useState([]);
    const [userId, setUserId] = useState(0);
    const [references, setReferences] = useState([]);
    const [payExchanges, setPayExchanges] = useState([]);
    const { t } = useTranslation();
    const [timeLeftMap, setTimeLeftMap] = useState({});

    const changeLanguage = (language) => {
        i18n.changeLanguage(language, (err, t) => {
            if (err) return console.log("Error loading language:", err);
            // console.log("Language changed to:", language);
        });
    };

    const isMobile = window.innerWidth <= 900;

    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadMethods();
                await loadReferences();
                await loadPayExchanges();
                const userId = localStorage.getItem("id");
                if (!userId) {
                    throw new Error("User ID not found in localStorage");
                }
                setUserId(userId);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [methods]);

    const loadMethods = async () => {
        try {
            const result = await axios.get("/exchanges", {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
                }
            });
            setMethods(result.data);
        } catch (error) {
            console.error("Error loading methods:", error);
        }
    };

    const loadReferences = async () => {
        try {
            const result = await axios.get("/pay_methods", {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
                }
            });
            setReferences(result.data);
        } catch (error) {
            console.error("Error loading references:", error);
        }
    };

    const loadPayExchanges = async () => {
        try {
            const result = await axios.get("/pay_exchange", {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
                }
            });
            setPayExchanges(result.data);
        } catch (error) {
            console.error("Error loading pay exchanges:", error);
        }
    };

    const userMethods = methods.filter(method => method.user_id === userId);

    
    const handleCancel = async (id) => {
        const result = await Swal.fire({
            title: t("Warning"),
            text: t("WarningText"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("confirmWarning"),
            cancelButtonText: t("CancelWarning"),
            reverseButtons: true
        });
    
        if (result.isConfirmed) {
            try {
                await axios.get(`/exchanges/cancel?id=${id}`, {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
                    }
                });
    
                await loadMethods(); 
                Swal.fire(t("gotCanceled"), t("warningDone"), 'success');
            } catch (error) {
                // Swal.fire('خطأ!', 'حدث خطأ أثناء إلغاء الطلب.', 'error');
            }
        }
    };
    

    const getTimeLeft = (exchangeDate, timeCancellation) => {
        if (!exchangeDate || !timeCancellation) return "00:00:00";

        const exchangeTimestamp = new Date(exchangeDate).getTime();

        const [hoursStr, minutesStr, secondsStr] = timeCancellation.split(':');
        const hours = parseInt(hoursStr);
        const minutes = parseInt(minutesStr);
        const seconds = parseInt(secondsStr);

        const expiration = exchangeTimestamp + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);

        const now = new Date().getTime();
        const timeLeft = expiration - now;

        if (timeLeft <= 0) return "00:00:00";

        const remainingHours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
        const remainingMinutes = Math.floor((timeLeft / (1000 * 60)) % 60);
        const remainingSeconds = Math.floor((timeLeft / 1000) % 60);

        return `${remainingHours}:${remainingMinutes}:${remainingSeconds}`;
    };

    const getPayExchangeTime = (firstMethod, secondMethod) => {
        const payExchange = payExchanges.find(pe => pe.first_method == firstMethod && pe.second_method == secondMethod);
        return payExchange ? payExchange.time_cancelation : "00:00:00"; 
    };

    const canBeCanceled = (firstMethod, secondMethod) => {
        const payExchange = payExchanges.find(pe => pe.first_method == firstMethod && pe.second_method == secondMethod);
        return payExchange ? payExchange.can_canceled == 1 : false;
    };
    const [showModal, setShowModal] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);

    const handleOpenModal = (method) => {
        setSelectedMethod(method);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMethod(null);
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            const updatedTimeLeftMap = {};
            methods.forEach((method) => {
                const timeLeft = getTimeLeft(method.date, getPayExchangeTime(method.first_method_id, method.second_method_id));
                updatedTimeLeftMap[method.id] = timeLeft;
            });
            setTimeLeftMap(updatedTimeLeftMap);
        }, 1000); // Update every second

        return () => clearInterval(intervalId); 
    }, [methods, payExchanges]);
    return (
        <>
            <section className="infos infodifferr" id="infos">
                <div className="container">
                    {isMobile ? (
                        <div className="mobile-view">
                            {userMethods.length === 0 ? (
                                <p style={{ textAlign: "center" }} className="my-5">{t("NoPrevioustransactions")}</p>
                            ) : (
                                userMethods.map((method, index) => (
                                    <div key={index} className="transaction-row">
                                        <div className="transaction-item">
                                            <strong>{t("PaymentProof")}:</strong>
                                            <span>
                                                <img src={`/exchange/uploads/exchanges/${method.image}`} alt="" height="100px" />
                                            </span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("SendFrom")}:</strong>
                                            <span>
                                                {references.map(ref => (
                                                    ref.id === method.first_method_id && (
                                                        <div key={ref.id}>
                                                            <img src={`/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                                            <p><span>{parse(ref.name_en)}</span></p>
                                                        </div>
                                                    )
                                                ))}
                                            </span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("SendAmount")}:</strong>
                                            <span>{method.amount_send}</span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("GetTo")}:</strong>
                                            <span>
                                                {references.map(ref => (
                                                    ref.id === method.second_method_id && (
                                                        <div key={ref.id}>
                                                            <img src={`/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                                            <p><span>{parse(ref.name_en)}</span></p>
                                                        </div>
                                                    )
                                                ))}
                                            </span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("GetAmount")}:</strong>
                                            <span>{method.amount_recieve}</span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("TransactionID")}:</strong>
                                            <span>{method.transaction_id}</span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("Status")}:</strong>
                                            <span>{method.canceled == 1?'Canceled' : method.status == 0 && method.approved == 0 ? 'Waiting For Approvel': method.status == 0 && method.approved == 1 ? 'Approved and Pending' :method.status == 0 && method.approved == 2 ? 'Refused' :method.status == 0 && method.approved == 4 ? 'Disabled' :method.status == 0 && method.approved == 3 ? 'Canceled' : method.status == 1 ? 'Processing' : method.status == 2 ? 'Completed' : method.status == 3 ? 'Refunded' : null}</span>
                                            </div>
                                        <div className="transaction-item">
                                            <strong>{t("Response")}:</strong>
                                            <span>{method.response?.length > 0 ? <Button variant="primary" onClick={() => handleOpenModal(method)}>
                                                <FaEye />
                                            </Button> : 'No Response'}</span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("TimeCanbeCanceled")}:</strong>
                                            <span>
                                            {method.approved == 0?
                                                        method.canceled == 1
                                                    ? 'Canceled'
                                                    : 
                                                    canBeCanceled(method.first_method_id, method.second_method_id) ? (
                                                        <>
                                                            <p> {timeLeftMap[method.id]== "00:00:00" ? 'No time Left' : timeLeftMap[method.id]  }</p>
                                                            {timeLeftMap[method.id] !== "00:00:00" && timeLeftMap[method.id] && (
                                                                <button onClick={() => handleCancel(method.id)}>Cancel</button>
                                                            )}
                                                        </>
                                                    ):"Can't be Canceled"
                                                    
                                                    :"Can't be Canceled"}
                                            </span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("Date")}:</strong>
                                            <span>{method.date}</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    ) : (
                        <Table>
                            <thead>
                                <tr className=" headerHome">
                                    <th>
                                        <div className="change">
                                            <div className="change-thumb">

                                                <p><span>{t("PaymentProof")}</span></p>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="change">
                                            <div className="change-thumb">

                                                <p><span>{t("SendFrom")}</span></p>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="amrcat-cap-content">
                                            <p><span> {t("SendAmount")}</span></p>
                                        </div>
                                    </th>

                                    <th>
                                        <div className="change">
                                            <div className="change-thumb">

                                                <p><span>{t("GetTo")}</span></p>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="amrcat-cap-content">
                                            <p><span> {t("GetAmount")}</span></p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="amrcat-cap-content">
                                            <p><span>{t("TransactionID")}</span></p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="amrcat-cap-content">
                                            <p><span>{t("Status")}</span></p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="amrcat-cap-content">
                                            <p><span>{t("Response")}</span></p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="amrcat-cap-content">
                                            <p><span> {t("TimeCanbeCanceled")}</span></p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="amrcat-cap-content">
                                            <p><span> {t("Date")}</span></p>
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {userMethods.length === 0 ? (
                                    <tr>
                                        <td colSpan="20" style={{ textAlign: "center" }}>{t("NoPrevioustransactions")}</td>
                                    </tr>
                                ) : (
                                    userMethods.map((method) => (
                                        <tr>
                                            <>
                                                <td>
                                                    <div className="amrcat-cap-content">
                                                        <img src={`/exchange/uploads/exchanges/${method.image}`} alt="" height="100px" />
                                                    </div>
                                                </td>

                                                {
                                                    references.map(refrence => (
                                                        refrence.id == method.first_method_id ?
                                                            <>
                                                                <td>
                                                                    <div className="change">
                                                                        <div className="change-thumb">
                                                                            <img src={`/exchange/uploads/pay_methods/${refrence.image}`} alt="" height="100px" />

                                                                            <p><span>{parse(refrence.name_en)}</span></p>
                                                                        </div>
                                                                    </div>
                                                                </td>


                                                                <td>
                                                                    <div className="amrcat-cap-content">
                                                                        <p><span>{method.amount_send} {refrence.currency}</span></p>
                                                                    </div>
                                                                </td>

                                                            </>
                                                            : null))}
                                                {
                                                    references.map(refrence => (
                                                        refrence.id == method.second_method_id ?
                                                            <>
                                                                <td>
                                                                    <div className="change">
                                                                        <div className="change-thumb">
                                                                            <img src={`/exchange/uploads/pay_methods/${refrence.image}`} alt="" height="100px" />

                                                                            <p><span>{parse(refrence.name_en)}</span></p>
                                                                        </div>
                                                                    </div>
                                                                </td>


                                                                <td>
                                                                    <div className="amrcat-cap-content">
                                                                        <p><span>{method.amount_recieve}  {refrence.currency}</span></p>
                                                                    </div>
                                                                </td>

                                                            </>
                                                            : null))}
                                                <td>
                                                    <div className="amrcat-cap-content">
                                                        <p><span>{method.transaction_id}</span></p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amrcat-cap-content">
                                                        <p><span>{method.canceled == 1?'Canceled' : method.status == 0 && method.approved == 0 ? 'Waiting For Approvel': method.status == 0 && method.approved == 1 ? 'Approved and Pending' :method.status == 0 && method.approved == 2 ? 'Refused' :method.status == 0 && method.approved == 4 ? 'Disabled' :method.status == 0 && method.approved == 3 ? 'Canceled' : method.status == 1 ? 'Processing' : method.status == 2 ? 'Completed' : method.status == 3 ? 'Refunded' : null}</span></p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amrcat-cap-content">

                                                        <p><span>{method.response?.length > 0 ? <Button variant="primary" onClick={() => handleOpenModal(method)}>
                                                            <FaEye />
                                                        </Button> : 'No Response'}</span></p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amrcat-cap-content">
                                                        <p><span>
                                                        {method.approved == 0?
                                                        method.canceled == 1
                                                    ? 'Canceled'
                                                    : 
                                                    canBeCanceled(method.first_method_id, method.second_method_id) ? (
                                                        <>
                                                            <p> {timeLeftMap[method.id]== "00:00:00" ? 'No time Left' : timeLeftMap[method.id]  }</p>
                                                            {timeLeftMap[method.id] !== "00:00:00" && timeLeftMap[method.id] && (
                                                                <button onClick={() => handleCancel(method.id)}>Cancel</button>
                                                            )}
                                                        </>
                                                    ):"Can't be Canceled"
                                                    
                                                    :"Can't be Canceled"}
                                                        </span></p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amrcat-cap-content">
                                                        <p><span>{method.date}</span></p>
                                                    </div>
                                                </td>


                                            </>

                                        </tr>
                                    )))}
                            </tbody>

                        </Table>
                    )}
                </div>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedMethod ? (
                            <>
                                <p> {parse(selectedMethod.response)}</p>

                            </>
                        ) : (
                            <p>No method selected.</p>
                        )}
                    </Modal.Body>
                </Modal>
            </section>

            <Footer />
        </>
    );
}

export default Profile;
