import React, { useState, useEffect } from "react";
import axios from "axios";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const UpdateUserData = () => {
  const [user, setUser] = useState({
    f_name: "",
    username: "",
    s_name: "",
    address: "",
    phone: ""
  });

  const [initialUser, setInitialUser] = useState({}); // To store initial data for comparison
  const { f_name, username, s_name, phone } = user;
  const [userId, setUserId] = useState(localStorage.getItem("id"));
  const [isFormValid, setIsFormValid] = useState(false); // Form validation state
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
    });
  };

  useEffect(() => {
    const userId = localStorage.getItem("id");
    if (!userId) {
      window.location.href = "/";
      return;
    }
    setUserId(userId);
    loadUser(userId);
  }, []);

  const loadUser = async (id) => {
    try {
      const result = await axios.get(`/users?id=${userId}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
        }
      });
      setUser(result.data);
      setInitialUser(result.data); // Store the initial data for comparison
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // Validation to check if form is valid and has changes
  useEffect(() => {
    const formHasChanges = JSON.stringify(user) !== JSON.stringify(initialUser);
    // const isComplete = f_name && username && s_name && phone;
    setIsFormValid(formHasChanges );
  }, [user, initialUser, f_name, username, s_name, phone]);

  const onSubmit = async (e) => {
    e.preventDefault();


    if (!f_name || !s_name || !username || !phone ) {
      Swal.fire({
        icon: 'error',
        title: 'Incomplete Form',
        text: 'Please fill in all the required fields.',
      });
      return;
    }
 
    const phoneRegex = /^\d{10,15}$/;
    if (!phoneRegex.test(phone)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Phone Number',
        text: 'Please enter a valid phone number (digits only).',
      });
      return;
    }
    const usernameRegex = /^[a-zA-Z0-9]{3,20}$/;
    if (!usernameRegex.test(username)) {
        Swal.fire({
            icon: 'error',
            title: 'Invalid Username',
            text: 'Username must be 3-20 characters long, alphanumeric, and cannot contain spaces.',
        });
        return;
    }
    let formData = new FormData();
    formData.append("f_name", f_name);
    formData.append("username", username);
    formData.append("s_name", s_name);
    formData.append("phone", phone);

    try {
      const response = await axios({
        method: "post",
        url: `/users?id=${userId}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
        }
      })
      if (response.data) {

        Swal.fire({
          icon: 'success',
          title: 'User data successfully updated.',
          showConfirmButton: false,
          timer: 1500
        })
        window.localStorage.setItem("name", username);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something Went Wrong',
          showConfirmButton: false,
          timer: 1500
        })
      }
    } catch (error) {
      console.error("Error updating data:", error);
      Swal.fire({
        icon: 'error',
        title: error ? error : 'Something Went Wrong',
        showConfirmButton: false,
        timer: 1500
      })
    }
  };

  return (
    <section className="main-banner" id="top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-9">
            <div className="singles-contacts-box">
              <div id="second-form" className="invitioncard updateprofilee py-5 text-center">
                <form onSubmit={onSubmit}>
                  <p>{t("FirstName")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterFirstName")}
                    name="f_name"
                    value={f_name}
                    required
                    onChange={onInputChange}
                  />
                  <p>{t("LastName")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterLastName")}
                    name="s_name"
                    value={s_name}
                    required
                    onChange={onInputChange}
                  />
                  <p>{t("Username")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterUsername")}
                    name="username"
                    value={username}
                    required
                    onChange={onInputChange}
                  />
                  <p>{t("Phone")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterPhone")}
                    name="phone"
                    value={phone}
                    required
                    onChange={onInputChange}
                  />
                  <button type="submit" disabled={!isFormValid} style={{ backgroundColor: !isFormValid ? "#725aeb84" : null, width: "50%" }}>
                    {t("Update")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateUserData;
