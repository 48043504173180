import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../Footer";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Button, Modal} from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import parse from 'react-html-parser';

function AllWithdraw() {
    const [methods, setMethods] = useState([]);
    const [userId, setUserId] = useState(0);
    const [references, setReferences] = useState([]);
    const { t } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language, (err, t) => {
            if (err) return console.log("Error loading language:", err);
            // console.log("Language changed to:", language);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadMethods();
                await loadReferences();
                const userId = localStorage.getItem("id");
                if (!userId) {
                    window.location.href = '/';
                    throw new Error("User ID not found in localStorage");
                }
                setUserId(userId);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error or redirect to login page
            }
        };
        fetchData();
    }, []);

    const loadMethods = async () => {
        try {
            const result = await axios.get("/withdraw?status=1",  { headers: {
                "Content-Type": "application/json" ,
                'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
        }});
            setMethods(result.data);
        } catch (error) {
            console.error("Error loading methods:", error);
        }
    };

    const loadReferences = async () => {
        try {
            const result = await axios.get("/pay_methods" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      
                        "Content-Type": "application/json"                    }});
            setReferences(result.data);
        } catch (error) {
            console.error("Error loading references:", error);
        }
    };


    const userMethods = methods.filter(method => method.user_id === userId);

    const [showModal, setShowModal] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);

    const handleOpenModal = (method) => {
        setSelectedMethod(method);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMethod(null);
    };



    return (
        <>
            <section className="infos infodifferr" id="infos">
                <div className="container">
                    <Table>
                        <thead>
                            <tr className=" headerHome">
                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("Amount")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("GetTo")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span>{t("Wallet")}</span></p>
                                    </div>
                                </th>

                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("Comment")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span>{t("Status")}</span></p>
                                    </div>
                                </th>
                                <th>
                                        <div className="amrcat-cap-content">
                                            <p><span>{t("Response")}</span></p>
                                        </div>
                                    </th>
                            
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span> {t("Date")}</span></p>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {userMethods.length === 0 ? (
                                <p style={{ textAlign: "center" }} className="my-5">{t("NoPreviousWithdraw")}</p>
                            ) : (
                                userMethods.map((method) => (
                                    <tr>
                                        <>
                                            {
                                                references.map(refrence => (
                                                    refrence.id == method.method_id ?
                                                        <>
                                                            <td>
                                                                <div className="amrcat-cap-content">
                                                                    <p><span>{method.balance} {refrence.currency}</span></p>
                                                                </div>
                                                            </td>



                                                            <td>
                                                                <div className="change">
                                                                    <div className="change-thumb">
                                                                        <img src={`/exchange/uploads/pay_methods/${refrence.image}`} alt="" height="100px" />

                                                                        <p><span>{parse(refrence.name_en)}</span></p>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </>
                                                        : null))}

                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.wallet}</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.comment}</span></p>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.status == 0 ? 'pending' :method.status == 1 ? 'processing':method.status == 2 ? 'approved':method.status == 3 ? 'rejected':'pending'}</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                    <div className="amrcat-cap-content">

                                                        <p><span>{method.response?.length > 0 ? <Button variant="primary" onClick={() => handleOpenModal(method)}>
                                                            <FaEye />
                                                        </Button> : 'No Response'}</span></p>
                                                    </div>
                                                </td>
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.date}</span></p>
                                                </div>
                                            </td>


                                        </>

                                    </tr>
                                )))}
                        </tbody>

                    </Table>
                </div>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedMethod ? (
                            <>
                                <p> {parse(selectedMethod.response)}</p>

                            </>
                        ) : (
                            <p>No method selected.</p>
                        )}
                    </Modal.Body>
                </Modal>
            </section>
            <Footer />
        </>
    );
}

export default AllWithdraw;
